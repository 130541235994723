<template>
  <div class="rankings">
    <h2>Guest List Rankings</h2>
    <div class="row">
      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Number of Check-Ins</h4>
          <div class="table-headers">
            <span>Name</span>
            <span>Check-Ins</span>
          </div>
          <div class="values-container">
            <div
              v-for="(user, index) in guestlist.checkIns"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>
              <span class="number" v-else> {{ index + 1 }} </span>
              <span class="name">
                <span>{{ user.name }}</span>
                <small>{{ user.email }}</small>
              </span>
              <span>{{ user.checkins }}</span>
            </div>
          </div>

          <div v-if="guestlist.checkIns.length === 0" class="table-value">
            <span>N/A</span>
          </div>
        </div>
      </div>
      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Guest List Submissions</h4>
          <div class="table-headers">
            <span>Name</span>
            <span>Submissions</span>
          </div>
          <div class="values-container">
            <div
              v-for="(user, index) in guestlist.submissions"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>
              <span class="number" v-else> {{ index + 1 }} </span>
              <span class="name">
                <span>{{ user.name }}</span>
                <small>{{ user.email }}</small>
              </span>
              <span>{{ user.submissions }}</span>
            </div>
          </div>
          <div v-if="guestlist.submissions.length === 0" class="table-value">
            <span>N/A</span>
          </div>
        </div>
      </div>

      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Most Guests</h4>
          <div class="table-headers">
            <span>Name</span>
            <span>Guests</span>
          </div>
          <div class="values-container">
            <div
              v-for="(user, index) in guestlist.eventGuests"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>
              <span class="number" v-else> {{ index + 1 }} </span>
              <span class="name">
                <span>{{ user.name }}</span>
                <small>{{ user.email }}</small>
              </span>
              <span>{{ user.guests }}</span>
            </div>
          </div>
          <div v-if="guestlist.eventGuests.length === 0" class="table-value">
            <span>N/A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guestlist-rankings",
  props: {
    url: String
  },
  head() {
    return {
      title: "Guest List Rankings"
    };
  },
  data() {
    return {
      guestlist: {
        checkIns: [],
        submissions: [],
        eventGuests: []
      }
    };
  },
  created() {
    this.$loader.start();
    this.$axios
      .post("/guestlist/rankings", {
        event: this.url
      })
      .then(({ data }) => {
        this.guestlist.checkIns = data.checkIns;
        this.guestlist.submissions = data.submissions;
        this.guestlist.eventGuests = data.mostGuests;

        this.$loader.stop();

        // this.$emit("set-title", response.data.title);

        // const promoters = response.data.promoters;
      });
  }
};
</script>

<style lang="less" scoped>
#Content > .content-inner .rankings {
  .tickets-sold {
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 280px;
    margin-right: 10px;
    .table {
      margin: 15px 0;
      // padding-right: 15px;
      border-right: 1px solid #333b3f;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .tickets-sold.col.third {
      width: calc(50% - 10px);
      flex-direction: column;

      .table {
        width: 100%;
        border-right: none;
      }
    }
  }
  @media screen and (max-width: 749px) {
    .tickets-sold.col.third {
      width: 100%;
    }
  }
  .table {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px;

    h4 {
      margin: 0 0 8px 0;
      text-align: center;
    }

    .table-headers {
      display: flex;
      justify-content: space-between;
      // padding-bottom: 5px;
      padding: 10px 16px;
      // margin-bottom: 5px;
      // border-bottom: 1px solid #333b3f;
    }

    .values-container {
      background: var(--input-background);
      /* text-indent: 14px; */
      /* width: 100%; */
      /* height: 24px; */
      padding: 16px 10px;
      font-family: inherit;
      font-size: 1em;
      color: var(--text);
      border: 1px solid transparent;
      border-radius: 5px;
      box-shadow: 1px 1px 2px -1px #ffffff54, inset 1px 1px 3px 0px #00000026;
    }

    .table-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .rank {
        width: 25px;
        margin-right: 12px;
        text-align: center;
      }
      .number {
        width: 25px;
        margin-right: 12px;
        text-align: center;
        font-size: 20px;
      }

      .name {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
