<template>
  <div>
    <FormulateForm @submit="submit">
      <div class="red" v-if="group.formError">
        <p>
          <b>ERROR:</b>
          Please add Two or More Items to create a package.
        </p>
      </div>
      <div class="row">
        <div class="col half">
          <FormulateInput
              label="Package Name"
              placeholder="Package Name"
              type="text"
              v-model="group.name"
              name="name"
              :disabled="group.sold > 0"
              :validation="['required']"
              :validation-messages="{ in: 'This name is already taken.' }"
          />
        </div>
        <div class="col half">
          <FormulateInput
              label="Package Image"
              type="image"
              v-model="group.image"
              name="image"
          />
        </div>
        <div class="col full">
          <FormulateInput
              type="text"
              name="description"
              label="Description"
              placeholder="Add something to write about your package."
              help="Description should include the items in your package."
              v-model="group.description"
              validation="max:200"
          />
        </div>
      </div>
      <FormulateInput
          type="group"
          name="sections"
          v-model="group.sections"
          label="Sections"
          add-label="+ Add Section"
          :repeatable="true"
          #default="{index}"
      >
        <div class="row">
          <div class="col half">
            <FormulateInput
                type="select"
                label="Add Previously Entered Section"
                placeholder="Section"
                name="_id"
                :options="dropSections"
                :value="getSelectedSection(index)"
                autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
                type="number"
                name="quantity"
                label="Available Quantity"
                help="Pulled from original inventory"
                @input="itemPrices()"
                placeholder="Enter amount"
                autocomplete="false"
                :max="getMaxSectionQty(index)"
            />
          </div>
        </div>
      </FormulateInput>
      <FormulateInput
          type="group"
          name="bottles"
          v-model="group.bottles"
          label="Bottles"
          add-label="+ Add Bottle"
          :repeatable="true"
          #default="{index}"
      >
        <div class="row">
          <div class="col half">
            <FormulateInput
                type="select"
                label="Add Previously Entered Bottle"
                placeholder="Bottle"
                name="_id"
                :options="dropBottles"
                :value="getSelectedBottle(index)"
                autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
                type="number"
                name="quantity"
                :min="group.sold || 0"
                label="Available Quantity"
                help="Pulled from original inventory"
                @input="itemPrices()"
                placeholder="Enter amount"
                autocomplete="false"
                :max="getMaxBottleQty(index)"
            />
          </div>
        </div>
      </FormulateInput>
      <FormulateInput
          type="group"
          name="foods"
          add-label="+ Add Food"
          label="Foods"
          v-model="group.foods"
          :repeatable="true"
          #default="{index}"
      >
        <div class="row">
          <div class="col half">
            <FormulateInput
                type="select"
                name="_id"
                label="Add Previously Entered Food"
                placeholder="Food"
                :options="dropFoods"
                :value="getSelectedFood(index)"
                autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
                type="number"
                name="quantity"
                label="Available Quantity"
                help="Pulled from original inventory"
                @input="itemPrices()"
                placeholder="Enter amount"
                autocomplete="false"
                :max="getMaxFoodQty(index)"
            />
          </div>
        </div>
      </FormulateInput>
      <div class="row">
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="item_prices"
              label="Item Prices"
              disabled
              v-model="group.item_prices"
              placeholder="Price"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="price"
              label="Package Price"
              v-model="group.price"
              @input="calcFees()"
              placeholder="Price"
              validation="required"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="ccfee"
              v-model="group.ccfee"
              disabled="true"
              label="CC Fee"
              placeholder="0.00"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="tfee"
              v-model="group.tfee"
              disabled="true"
              label="T-Fee"
              placeholder="0.00"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="addfee"
              @input="calcFees()"
              v-model="group.addfee"
              label="Add Fees"
              placeholder="0.00"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="money"
              name="total"
              v-model="group.total"
              label="Total"
              disabled="true"
              placeholder="0.00"
              autocomplete="false"
          />
        </div>
        <div class="col tenth">
          <FormulateInput
              type="number"
              name="quantity"
              v-model="group.quantity"
              label="Pkgs Quantity"
              placeholder="Quantity"
              autocomplete="false"
              validation="required"
              :max="maxGroupQuantity"
          />
        </div>
        <div class="col tenth update-btn">
          <FormulateInput
              type="submit"
              :name="group.btn || 'Save'"
              :disabled="isSubmitted"
              autocomplete="false"
          />
        </div>
        <div class="col tenth delete">
          <FormulateInput
              type="button"
              autocomplete="false"
              name="Delete"
              :disabled="isDeleted || group.sold > 0"
              @click.prevent="handleRemove()"
          />
        </div>
        <div class="col quarterx3 flex center">
          Individual item fees do not apply to a package. This includes your
          Add Fee.
        </div>
        <div class="col full">
          <div class="divider"></div>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: "GroupForm",
  props: {
    group: {
      type: Object,
      default: {
        btn: "Create",
        sections: [],
        foods: [],
        bottles: [],
      }
    },

    tfees: Array,
    dropSections: Array,
    dropFoods: Array,
    dropBottles: Array,
    sections: Array,
    bottles: Array,
    foods: Array,
    settings_id: String,
  },
  data() {
    return {
      isSubmitted: false,
      isDeleted: false,
      ccPercent: 0.026,
    }
  },
  computed: {
    getMaxSectionQty() {
      return (index) => {
        let selected = this.group.sections[index];
        if (selected === undefined) {
          return 99999;
        }
        let section = this.sections.find(s => s._id === selected._id);
        if (section === undefined) {
          return 99999;
        }
        if (selected.oldQty) {
          return section.available + selected.oldQty;
        }
        return section.available;
      }
    },
    getMaxBottleQty() {
      return (index) => {
        let selected = this.group.bottles[index];
        if (selected === undefined) {
          return 99999;
        }
        let bottle = this.bottles.find(s => s._id === selected._id);
        if (bottle === undefined) {
          return 99999;
        }
        if (selected.oldQty) {
          return bottle.available + selected.oldQty;
        }
        return bottle.available;
      }
    },
    getMaxFoodQty() {
      return (index) => {
        let selectedFood = this.group.foods[index];
        if (selectedFood === undefined) {
          return 99999;
        }
        let food = this.foods.find(s => s._id === selectedFood._id);
        if (food === undefined) {
          return 99999;
        }
        if (selectedFood.oldQty) {
          return food.available + selectedFood.oldQty;
        }
        return food.available;
      }
    },
    maxGroupQuantity() {
      let max = 99999;
      this.group.sections.forEach((section, index) => {
        let itemMaxQty = this.getMaxSectionQty(index) / section.quantity;
        if (itemMaxQty < 1) {
          max = 0;
        } else {
          itemMaxQty = parseInt(itemMaxQty);
          if (max > itemMaxQty) {
            max = itemMaxQty;
          }
        }
      });
      this.group.bottles.forEach((bottle, index) => {
        let itemMaxQty = this.getMaxBottleQty(index) / bottle.quantity;
        if (itemMaxQty < 1) {
          max = 0;
        } else {
          itemMaxQty = parseInt(itemMaxQty);
          if (max > itemMaxQty) {
            max = itemMaxQty;
          }
        }
      });
      this.group.foods.forEach((food, index) => {
        let itemMaxQty = this.getMaxFoodQty(index) / food.quantity;
        if (itemMaxQty < 1) {
          max = 0;
        } else {
          itemMaxQty = parseInt(itemMaxQty);
          if (max > itemMaxQty) {
            max = itemMaxQty;
          }
        }
      });
      return max;
    }
  },
  methods: {
    getSelectedSection(index) {
      if (index >= 0 && this.group.sections[index] != []) {
        return this.group.sections[index]._id || null;
      }
      return null;
    },
    getSelectedBottle(index) {
      if (index >= 0 && this.group.bottles[index] != []) {
        return this.group.bottles[index]._id || null;
      }
      return null;
    },
    getSelectedFood(index) {
      if (index >= 0 && this.group.foods[index] != []) {
        return this.group.foods[index]._id || null;
      }
      return null;
    },
    itemPrices() {
      let sectionsTotal = 0.0;
      let bottlesTotal = 0.0;
      let foodsTotal = 0.0;
      if (
          this.group.sections &&
          this.group.sections.length > 0
      ) {
        this.group.sections.forEach(groupSection => {
          let id = groupSection._id;
          let item = this.sections.find(s => s._id === id);
          sectionsTotal += groupSection.quantity * item.price;
        });
      }

      if (this.group.bottles && this.group.bottles.length > 0) {
        this.group.bottles.forEach(groupBottle => {
          let id = groupBottle._id;
          let item = this.bottles.find(s => s._id === id);
          bottlesTotal += groupBottle.quantity * item.price;
        });
      }
      if (this.group.foods && this.group.foods.length > 0) {
        this.group.foods.forEach(groupFood => {
          let id = groupFood._id;
          let item = this.foods.find(s => s._id === id);
          foodsTotal += groupFood.quantity * item.price;
        });
      }

      let total = (sectionsTotal + bottlesTotal + foodsTotal).toFixed(2);
      this.$set(this.group, "item_prices", total);
    },
    submit() {
      this.isDeleted = true;
      this.group.btn = 'Update';
      this.$emit('save');
      setTimeout(() => {
        this.isSubmitted = false;
      }, 1000);
    },
    handleRemove() {
      this.isDeleted = true;
      this.$emit('remove');
      setTimeout(() => {
        this.isDeleted = false;
      }, 1000);
    },
    calcFees() {
      this.calcTFee();
      this.calcCCFee();
      this.calcTotal();
    },
    calcTotal() {
      let section = this.group;
      let total = 0;
      total += Number(section.price);
      total += Number(section.tfee);
      total += Number(section.ccfee);
      total += Number(section.addfee || 0);

      this.$set(this.group, "total", total);
    },
    calcCCFee() {
      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   return Number(this.cost) * this.AMEXfee;
      // }
      //  (
      //   (Number(this.cost) +
      //     Number(this.formValues.dfee) +
      //     Number(this.taxes)) *
      //   this.ccPercent
      // );

      this.$set(
          this.group,
          "ccfee",
          (
              (Number(this.group.price || 0) +
                  Number(this.group.tfee || 0) +
                  Number(this.group.addfee || 0)) *
              Number(this.ccPercent)
          ).toFixed(2)
      );
    },
    calcTFee() {
      let price = this.group.price || 0;
      let addfee = this.group.addfee || 0;

      const cost = Number(price) + Number(addfee);
      let tfee = 0;
      for (let i = 0; i < this.tfees.length; i++) {
        let ele = this.tfees[i];
        if (cost >= ele.min && cost <= ele.max) {
          this.$set(this.group, "tfee", ele.value.toFixed(2));
          tfee = ele.value;
          i = this.tfees.length;
        } else if (cost === 0) {
          this.$set(this.group, "tfee", 0);
        }
      }

      // let VMCFee;
      // let AMEXFee;

      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   VMCFee = cost * this.VMCfee;
      //   AMEXFee = cost * this.AMEXfee;
      // } else {
      //   VMCFee = (cost + tfee + Number(this.taxes)) * this.VMCfee;
      //   AMEXFee = (cost + tfee + Number(this.taxes)) * this.AMEXfee;
      // }

      // this.formValues.VMCfee = VMCFee.toFixed(2);
      // this.formValues.AMEXfee = AMEXFee.toFixed(2);
    },
  }
}
</script>

<style lang="less" scoped>
.col.tenth.update-btn {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="submit"] button {
    height: 48px;
    width: 100%;
  }
}

.col.tenth.delete {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="button"] button {
    background: var(--error-red);
    height: 48px;
    width: 100%;
  }
}

</style>