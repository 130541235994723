<template>
  <div class="list-settings-container">
    <alert-modal ref="alert"/>
    <h2>Settings</h2>

    <div class="copy-events-wrapper row" v-if="!settings">
      <div class="copy-events col half flex center">
        <FormulateInput
            label="Copy from previous events?"
            placeholder="Select An Event"
            :options="eventList"
            v-model="copyEvent"
            :disabled="disableSettings"
            type="select"
            name="methodType"
            validation="required"
            @input="setCopiedSettings"
        />
        <!-- <diyobo-input
          type="dropdown"
          label="Copy from previous events?"
          placeholder="Select An Event"
          :options="eventList"
          :val="copyEvent"
          @change="copySettings"
          v-model="copyEvent"
          :readOnly="disableSettings"
        /> -->
      </div>
    </div>
    <div class="divider"></div>
    <div class="inner-container">
      <FormulateForm
          @submit="save"
          ref="formSettings"
          v-model="formValues"
          name="bulkForm"
      >
        <div class="checkboxes row">
          <div class="col third ">
            <FormulateInput
                label="Enable guest list"
                type="checkbox"
                name="enable"
            />
            <FormulateInput
                label="Auto-approve requests"
                type="checkbox"
                name="autoApprove"
            />
            <FormulateInput
                label="Collect Birthdays"
                type="checkbox"
                name="collectBirthdays"
            />
            <FormulateInput
                label="Send day of reminder (CRM charges apply)"
                type="checkbox"
                name="dayReminder"
            />
            <FormulateInput
                label="Segment entrants based on Keywords (uses AI)"
                type="checkbox"
                name="basedKeywords"
            />
            <FormulateInput
                label="Display Promoter Referral Option"
                type="checkbox"
                name="promoterReferal"
            />
            <FormulateInput
                label="Make Guest List Options Available in Discounts"
                type="checkbox"
                name="discountsAvailable"
            />
            <FormulateInput
                label="Allow our AI to learn your customer data."
                type="checkbox"
                name="allowAi"
            />
          </div>
          <div class="col third ">
            <FormulateInput
                label="Make Guest List Options Available in Comps"
                disabled
                type="checkbox"
                name="compsAvailable"
            />
            <FormulateInput
                label="Allow requests for Booking of Sections"
                type="checkbox"
                :disabled="hasSectionItems"
                name="offerSections"
            />
            <FormulateInput
                label="Allow requests for Bottle Service Bookings"
                type="checkbox"
                :disabled="hasBottleItems"
                name="offerBottles"
            />
            <FormulateInput
                label="Allow requests for a Food Menu"
                type="checkbox"
                name="offerMenu"
                :disabled="hasFoodItems"
            />
            <FormulateInput
                label="Allow purchasing of Sections"
                type="checkbox"
                name="chargeSections"
            />
            <FormulateInput
                label="Allow purchasing of Bottles"
                type="checkbox"
                name="chargeBottles"
            />
            <FormulateInput
                label="Allow purchasing of Food"
                type="checkbox"
                name="chargeFood"
            />
            <FormulateInput
                label="Require purchase to submit a guest list"
                type="checkbox"
                name="requirePurchase"
            />
          </div>
          <div class="row close" v-if="settings">
            <div class="col quarter flex center">
              <FormulateInput
                  label="CLOSE GUEST LIST"
                  type="checkbox"
                  name="closeGuestlist"
              />
            </div>
          </div>
        </div>

        <div v-if="event && event.frequency !== 'SINGLE_DATE'">
          <div class="divider"></div>
          <h2 class="text-center">Additional Options</h2>
          <p class="text-center">
            Please select all applicable options and we will populate the fields
            required on your event page and list embed.
          </p>
          <div class="slidecontainer row">
            <div class="col quarterx3 flex center">
              <span>1</span>
              <FormulateInput
                  :label="
                  `How many weeks in advance would you like to allow bookings? ${formValues.advanceWeeks}`
                "
                  type="range"
                  name="advanceWeeks"
                  min="1"
                  max="24"
                  validation="required|min:1|max:24"
                  error-behavior="live"
              />
              <span>24</span>
            </div>
          </div>
        </div>
        <div class="divider"></div>

        <div class="row">
          <div class="col half">
            <FormulateInput
                label="Max Guest List Size"
                placeholder="Enter Amount"
                type="number"
                name="maxListSize"
                validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
                label="Minimum Group Size"
                placeholder="Enter Amount"
                type="number"
                name="minGroupSize"
                validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
                label="Maximum Group Size"
                placeholder="Enter Amount"
                type="number"
                name="maxGroupSize"
                validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
                label="Close Guest List Automatically at:"
                placeholder="Enter Amount"
                type="time"
                :model-config="modelConfig"
                name="registrationEnd"
                validation="required"
                help="Guest list closes at this time on the event date only."
            />
          </div>
          <div class="col half">
            <FormulateInput
                label="Auto Gratuity Amount"
                placeholder="Enter a Percentage"
                type="number"
                name="autoGratutity"
            />
          </div>
        </div>

        <div class="divider"></div>

        <h2>Reservation Details</h2>
        <p>
          This section displays at the top of your guest list. Please include
          any important information such as Dress Code, ID Requirements, etc.
          You are not able to remove the policy details from your form.
        </p>

        <FormulateInput
            class="some-random-class"
            type="group"
            name="reservationDetails"
            :repeatable="true"
            add-label="Add Another Detail"
            minimum="1"
            autocomplete="false"
        >
          <div class="row">
            <div class="col full">
              <FormulateInput
                  label="Add Reservation Details"
                  placeholder="Type your detail here"
                  type="text"
                  name="value"
                  validation="required"
              />
            </div>
          </div>
        </FormulateInput>

        <div class="divider"></div>
        <h2>Add Time Slots</h2>
        <p>
          <b>
            Total Guest List Size: {{ totalSize }}/{{ formValues.maxListSize }}
          </b>
        </p>
        <FormulateInput
            class="some-random-class"
            type="group"
            name="timeSlots"
            :repeatable="true"
            #default="{ index }"
            add-label="Add Another Check-In Time"
            minimum="1"
            autocomplete="false"
        >
          <div class="row">
            <div class="col half">
              <FormulateInput
                  label="Time"
                  placeholder="HH:MM A/P"
                  type="time"
                  :model-config="modelConfig"
                  name="value"
                  validation="required"
              />
            </div>
            <div class="col half">
              <FormulateInput
                  label="Total Available"
                  placeholder="Enter amount"
                  type="number"
                  name="amount"
                  @input="checkMaxSlotAvailable($event, index)"
                  validation="required|number"
                  :error="slotErrors[index] || ''"
              />
            </div>
          </div>
        </FormulateInput>
        <div class="divider"></div>
        <div class="row">
          <div class="col half flex center">
            <p>
              Click the button below to save the settings above.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col quarter flex center">
            <FormulateInput
                class="col half"
                type="submit"
                :name="saveSettingsBtnName"
                :disabled="isSubmitted"
                autocomplete="false"
            />
          </div>
        </div>
        <div class="note">
          <card>
            <p>
              <b>NOTE:</b>
              Once you have saved your settings and accepted your first guest
              list reservation, you will not be able to select a new template to
              edit from and your auto-approval settings will be locked in as
              well. Below, simply save your individual items (no group save
              needed).
            </p>
          </card>
        </div>
        <div class="divider"></div>
      </FormulateForm>

      <section v-if="settings && formValues.offerSections">
        <Accordion title="Add Sections/Tables" v-model="sectionDropdown">
          <Section
              :useReservSeats="event.eventType == 'RESERVED_SEATS'"
              :settings_id="settings._id"
              :tfees="tfees"
              :paid="formValues.chargeSections"
              @changed="hasChange"
          />
        </Accordion>
      </section>

      <section v-if="settings && formValues.offerBottles">
        <Accordion title="Add Bottles" v-model="bottleDropdown">
          <Bottles
              :settings_id="settings._id"
              :tfees="tfees"
              :paid="formValues.chargeBottles"
              @changed="hasChange"
          />
        </Accordion>
      </section>
      <section v-if="settings && formValues.offerMenu">
        <Accordion title="Add Food" v-model="foodDropdown">
          <Food
              :settings_id="settings._id"
              :tfees="tfees"
              :paid="formValues.chargeFood"
              @changed="hasChange"
          />
        </Accordion>
      </section>
      <section v-if="settings">
        <Accordion title="Add Packages" v-model="groupDropdown">
          <Group
              :settings_id="settings._id"
              :tfees="tfees"
              :sections="[]"
              :bottles="[]"
              :foods="[]"
              :hasChanges="someChanged"
          />
        </Accordion>
      </section>
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboTimePicker from "../../components/diyoboTimePicker";
import InfoText from "@/components/InfoText.vue";

import {formatPhoneNumber} from "@/helpers/input-formats.js";
import TableTote from "@/components/TableTote.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faAngleDown, faLink} from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "@/components/ColorPicker.vue";

import * as yup from "yup";
import ModalContent from "@/helpers/modals";
import Section from "../../components/guestlist/Section.vue";
import Group from "../../components/guestlist/GLPurchaseGrouping.vue";

import Accordion from "@/components/Accordion";
import Bottles from "../../components/guestlist/Bottles.vue";
import Food from "../../components/guestlist/Food.vue";
import AlertModal from "@/components/modals/AlertModal.vue";

library.add(faAngleDown, faLink);

const VALIDATION_SCHEMA = yup.object({
  maxListSize: yup.string().required("Max Guest List Size is required."),
  minGroupSize: yup.string().required("Min Group Size is required."),
  maxGroupSize: yup.string().required("Max Group Size is required."),
  timeSlots: yup
      .array()
      .min(1, "Add Atleast 1 Timeslot")
      .required("Time Slot is required"),
  sections: yup
      .array()
      .min(1, "Add Atleast 1 Section/Table")
      .required("Section/Table is required")
});

export default {
  name: "list-settings",
  components: {
    DiyoboInput,
    DiyoboButton,
    TableTote,
    DiyoboCheckbox,
    FontAwesomeIcon,
    ColorPicker,
    DiyoboTimePicker,
    InfoText,
    Section,
    Group,
    Accordion,
    Bottles,
    Food,
    AlertModal
  },
  head() {
    return {
      title: "Settings"
    };
  },
  props: {
    url: String
  },

  data() {
    return {
      initialFormValues: {},
      event: [],
      saveSettingsBtnName: "Create",
      isSubmitted: false,
      value: 0,
      modelConfig: {
        type: "string",
        mask: "hh:mm a"
      },
      sectionDropdown: false,
      bottleDropdown: false,
      foodDropdown: false,
      tosDropdown: false,
      timeDropdown: false,
      groupDropdown: false,

      eventList: [],
      copyEvent: "",
      embedCode: "",
      disableSettings: false,
      formValues: {
        enable: false,
        autoApprove: false,
        collectBirthdays: false,
        dayReminder: false,
        basedKeywords: false,
        promoterReferal: false,
        discountsAvailable: false,
        compsAvailable: false,
        offerSections: false,
        offerBottles: false,
        offerMenu: false,
        chargeSections: false,
        chargeBottles: false,
        chargeFood: false,
        requirePurchase: false,
        allowAi: true,
        maxListSize: "",
        minGroupSize: "",
        maxGroupSize: "",
        reservationDetails: [],
        timeSlots: [],
        advanceWeeks: 10,
        registrationEnd: null,
        autoGratutity: null
        //  closeGuestlist: false,
        //   embedOptions: {
        //     bgColor: "#222429",
        //     borderColor: "#404143",
        //     textColor: "#ffffff",
        //     inputBgColor: "#1f2025",
        //     inputTextColor: "#ffffff",
        //     targetWidth: "100%"
        //   }
      },
      formErrors: {
        enable: "",
        maxListSize: "",
        minGroupSize: "",
        maxGroupSize: "",
        sections: "",
        reservationDetails: "",
        timeSlots: ""
      },
      settings: null,
      section: "",
      tfees: [],
      range: "",
      startTime: "",
      endTime: "",
      cash: false,
      reservationDetails: "",
      countries: [
        {label: "Canada", value: "Canada"},
        {
          label: "United States",
          value: "United States"
        }
      ],
      slotErrors: [],
      someChanged: false,
    };
  },
  computed: {
    totalSize() {
      return this.formValues.timeSlots.reduce(function (a, {amount}) {
        return parseInt(a) + parseInt(amount || 0);
      }, 0);
    },
    embedLink() {
      let currentEnv = process.env.VUE_APP_ENVIRONMENT;

      if (currentEnv === "staging") {
        return "https://staging.incredevent.com/";
      } else if (currentEnv === "production") {
        return "https://incredevent.com/";
      } else {
        return "http://localhost:3100/";
      }
    },
    hasSectionItems() {
      if (this.settings) {
        return this.settings.hasSection;
      }
      return false;
    },
    hasFoodItems() {
      if (this.settings) {
        return this.settings.hasFood;
      }
      return false;
    },
    hasBottleItems() {
      if (this.settings) {
        return this.settings.hasBottle;
      }
      return false;
    },
    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    }
  },
  async mounted() {
    this.$loader.start();
    await this.$axios
        .get(`/get-event/${this.$route.params.url}`)
        .then(response => {
          this.event = response.data;
        });
    this.$axios
        .post("/guestlist/settings/get", {
          url: this.url
        })
        .then(response => {
          const data = response.data;
          if (data._id) {
            this.settings = data;
            this.setSettingsToForm(this.settings);
            this.saveSettingsBtnName = "Update";
          }
        });

    this.$axios
        .post("/guestlist/get-guestlist-events", {
          event: this.url,
          copy: true
        })
        .then(response => {
          const data = response.data;
          let newEventArr = [];
          data.events.forEach(event => {
            newEventArr.push({
              label: event.name,
              value: event.url,
              settings: event.guestlist_settings
            });
          });

          this.eventList = newEventArr;
        });

    this.getEventTFees();

    this.$loader.stop();
  },
  methods: {
    hasChange() {
      this.someChanged = !this.someChanged;
    },
    checkMaxSlotAvailable(value, index) {
      this.slotErrors[index] = "";
      let maxValue = this.formValues.maxListSize - this.totalSize;
      if (maxValue < 0) {
        this.slotErrors[index] =
            "Your available size is greater then max guest list size.";
      }
    },
    getEventTFees() {
      this.$axios
          .post(`/planners/retrieve-tier-fees`, {
            event: this.url
          })
          .then(response => {
            if (response.status === 200) {
              let feeObj = response.data.fee_Obj;

              this.feesStructure = feeObj.ticketData;
              let feesStructure = [];

              feeObj.ticketData.forEach(e => {
                feesStructure.push(
                    `$${e.startAmount.$numberDecimal} - $${e.endAmount.$numberDecimal} : $${e.Guestlist.$numberDecimal} `
                );
              });
              this.feesStructure = feesStructure;

              // START NEW FEES
              let VMCFeeToUse = feeObj.feeData.VMCFee
                  ? parseFloat(feeObj.feeData.VMCFee.$numberDecimal) / 100
                  : 0.017;

              let AMEXFeeToUse = feeObj.feeData.AMEXFee
                  ? parseFloat(feeObj.feeData.AMEXFee.$numberDecimal) / 100
                  : 0.026;

              // END NEW FEES
              let feeArray = [];
              feeObj.ticketData.forEach(ticketFee => {
                let objToInsert = {
                  min: parseFloat(ticketFee.startAmount.$numberDecimal || ticketFee.startAmount),
                  max: parseFloat(ticketFee.endAmount.$numberDecimal || ticketFee.endAmount),
                  value: parseFloat(ticketFee.Guestlist.$numberDecimal || ticketFee.endAmount)
                };
                feeArray.push(objToInsert);
              });

              this.tfees = feeArray;
              this.VMCfee = VMCFeeToUse;
              this.AMEXfee = AMEXFeeToUse;
            }
          });
    },
    setSettingsToForm(settings) {
      this.formValues = {
        enable: settings.enable,
        autoApprove: settings.auto_approve,
        collectBirthdays: settings.collect_birthdays,
        dayReminder: settings.day_reminder,
        basedKeywords: settings.based_keywords,
        promoterReferal: settings.promoter_referal,
        discountsAvailable: settings.discounts_available,
        compsAvailable: settings.comps_available,
        offerSections: settings.offer_sections,
        offerBottles: settings.offer_bottles,
        offerMenu: settings.offer_menu,
        chargeSections: settings.charge_sections,
        chargeBottles: settings.charge_bottles,
        chargeFood: settings.charge_food,
        allowAi: settings.allow_ai || false,
        maxListSize: settings.max_list_size,
        minGroupSize: settings.min_group_size,
        maxGroupSize: settings.max_group_size,
        requirePurchase: settings.require_purchase,
        reservationDetails: settings.reservation_details,
        timeSlots: settings.time_slots,
        advanceWeeks: settings.advance_weeks,
        registrationEnd: settings.registration_end.$date
            ? new Date(
                parseInt(settings.registration_end.$date.$numberLong)
            ).toISOString()
            : settings.registration_end,
        autoGratutity: settings.auto_gratutity,
        closeGuestlist: settings.close_guestlist || false
      };
      this.initialFormValues = {...this.formValues};
    },
    setCopiedSettings() {
      let event = this.eventList.find(t => t.value === this.copyEvent);
      this.setSettingsToForm(event.settings[0]);
    },
    copySettings(event, name) {
      this.$loader.start();

      this.$axios
          .post("/guestlist/get-guestlist-settings", {
            event: event
          })
          .then(response => {
            const data = response.data;

            if (data) {
              this.embedURL = `${this.embedLink + this.url}/guestlist-embed`;

              this.embedCode = `<iframe src="${this.embedLink +
              this
                  .url}/guestlist-embed" frameborder="0" height="700px" width="100%>`;

              this.formValues.enable = data.enable;
              this.formValues.autoApprove = data.autoApprove || false;
              this.formValues.closeGuestlist = data.close_guestlist || false;
              this.formValues.maxListSize = data.max_list_size;
              this.formValues.minGroupSize = data.min_group_size;
              this.formValues.maxGroupSize = data.max_group_size;
              this.formValues.sections = data.sections || [];
              this.formValues.reservationDetails = data.reservation_details || [];
              this.formValues.timeSlots = data.time_slots || [];
            }
            this.$loader.stop();
            this.$toast.success(`Settings are copied from ${name}`);
          });
    },
    updateCash(index, value) {
      if (value) {
        this.formValues.sections[index].label = this.formValues.sections[
            index
            ].value;
      } else {
        this.formValues.sections[index].label = this.formValues.sections[
            index
            ].value;
      }
    },
    updateSection(index, value) {
      this.formValues.sections[index].value = value;
      this.formValues.sections[index].label = value;
    },
    updateRD(index, value) {
      this.formValues.reservationDetails[index].value = value;
      this.formValues.reservationDetails[index].label = value;
    },
    updateTimeSlots(index, value) {
      this.formValues.timeSlots[index].value = value;
      this.formValues.timeSlots[index].label = value;
    },
    setTime(obj, id, type, time) {
      if (obj === "start-time") {
        this.startTime = time;
      } else {
        this.endTime = time;
      }
    },
    addTimeSlot() {
      if (this.startTime) {
        this.formValues.timeSlots.push({
          label: `${this.startTime}`,
          value: `${this.startTime}`
        });

        this.startTime = "";
      }
    },
    getTime(unixTimeStamp) {
      let hour = dateFormat(new Date(+unixTimeStamp), "hh:MM TT");
      return hour;
    },
    addSections() {
      if (this.section) {
        this.formValues.sections.push({
          label: `${this.section}`,
          value: `${this.section}`,
          cash: this.cash
        });
        this.section = "";
      }
    },
    addreservationDetails() {
      if (this.reservationDetails) {
        this.formValues.reservationDetails.push({
          label: this.reservationDetails,
          value: this.reservationDetails
        });
        this.reservationDetails = "";
      }
    },
    async validateAt(field) {
      return VALIDATION_SCHEMA.validateAt(field, this.formValues)
          .then(() => {
            this.formErrors[field] = "";
          })
          .catch(err => {
            this.formErrors[field] = err.message;
          });
    },
    async validateData() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return;
      }

      this.submitData();
    },
    async saveEmbedSettings(options) {
      this.$loader.start();

      const settings = options;

      this.$axios
          .post("/guestlist/set-embed-settings", {
            settings: settings,
            event: this.url
          })
          .then(response => {
            console.log(response);

            // this.clearInputs();
            this.$store.state.bus.$emit("alert", ModalContent.guestSettings);
            this.$loader.stop();
          });
    },
    save() {
      this.isSubmitted = true;
      this.formValues.url = this.url;
      if (!this.settings) {
        this.$axios
            .post("/guestlist/settings/create", this.formValues)
            .then(response => {
              this.settings = response.data.item;
              this.$toast(response.data.message, {type: "success"});
            });
      } else {
        this.formValues.setting_id = this.settings._id;
        this.$axios
            .post("/guestlist/settings/update", this.formValues)
            .then(response => {
              this.settings = response.data.item;
              this.$toast(response.data.message, {type: "success"});
            });
      }
      this.isSubmitted = false;
      this.saveSettingsBtnName = "Update";
      this.initialFormValues = {...this.formValues};
    },
    submitData() {
      this.$loader.start();

      const email = this.formValues.email;

      const settingsObj = {
        enable: this.formValues.enable,
        autoApprove: this.formValues.autoApprove,
        closeGuestlist: this.formValues.closeGuestlist,
        maxListSize: this.formValues.maxListSize,
        minGroupSize: this.formValues.minGroupSize,
        maxGroupSize: this.formValues.maxGroupSize,
        sections: this.formValues.sections,
        reservationDetails: this.formValues.reservationDetails,
        timeSlots: this.formValues.timeSlots
      };

      this.$axios
          .post("/guestlist/set-guestlist-settings", {
            settings: settingsObj,
            event: this.url
          })
          .then(response => {
            this.clearErrors();
            this.$store.state.bus.$emit("alert", ModalContent.guestSettings);
            this.$loader.stop();
          });
    },
    clearInputs() {
      this.formValues = {
        enable: false,
        maxListSize: "",
        minGroupSize: "",
        maxGroupSize: "",
        sections: [],
        reservationDetails: []
      };
    },
    clearErrors() {
      this.formErrors = {
        enable: "",
        maxListSize: "",
        minGroupSize: "",
        maxGroupSize: "",
        sections: "",
        reservationDetails: "",
        timeSlots: ""
      };
    },
    isFormChanged() {
      const keys1 = Object.keys(this.initialFormValues);
      for (let key of keys1) {
        if (this.initialFormValues[key] !== this.formValues[key]) {
          return true;
        }
      }
      return false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormChanged()) {
      this.$refs.alert.open({
        title: "Pending Changes ",
        msg:
            "You have changes you haven't saved yet. Would you still like to exit?",
        confirm: "Save Changes",
        cancelBtn: "Ignore Changes",
        closable: true,
        onConfirm: () => {
          this.save();
        },
        onClose: () => {
          next();
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="less">
.v-application--wrap {
  min-height: initial;
}

.value-slider {
  .v-slider--horizontal .v-slider__track-container {
    height: 5px;
  }

  .theme--dark.v-slider .v-slider__track-background,
  .theme--dark.v-slider .v-slider__track-fill {
    border-radius: 5px;
  }

  .value-slider .theme--dark.v-slider .v-slider__thumb {
    border-radius: 30px;
  }

  .v-slider__thumb {
    height: 18px;
    width: 18px;
  }
}

.b1 {
  font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.text-center {
  text-align: center;
}

#Content .content-inner .divider {
  margin: 16px auto;
}

#Content .content-inner .list-settings-container {
  .input-wrapper {
    margin-bottom: 16px;
  }

  .close.row {
    margin: 16px 0;

    ::v-deep.formulate-input[data-classification="box"]
    .formulate-input-wrapper {
      justify-content: center;

      .b1 {
        color: red;
        font-weight: bold;
        font-size: 16px !important;
      }
    }

    ::v-deep.formulate-input[data-classification="box"]
    .formulate-input-element {
      .formulate-input-element-decorator {
        border: 1px solid #4e5360;
      }

      input[type="checkbox"]:checked ~ .formulate-input-element-decorator {
        border: 1px solid #4e5360;

        &::before {
          background: red;
        }
      }

      input:focus ~ .formulate-input-element-decorator {
        border: 1px solid #4e5360;
      }
    }
  }

  .copy-events-wrapper.row {
    display: flex;
    justify-content: center !important;
  }

  .inner-container {
    .slidecontainer {
      width: 100%;
      margin-bottom: 30px;

      ::v-deep.formulate-input-element.formulate-input-element--range {
        input[type="range"] {
          appearance: none;
          -webkit-appearance: none;
          width: 100%;
          height: 5px;
          border-radius: 10px;
          background: #d3d3d3;
          accent-color: var(--primary);
          outline: none;
          opacity: 1;
          // -webkit-transition: 0.2s;
          // transition: opacity 0.2s;

          // &:hover {
          //   opacity: 0.7;
          // }

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            background: var(--primary);
            cursor: pointer;
          }

          &::-moz-range-thumb {
            width: 27px;
            height: 27px;
            border-radius: 50%;
            background: var(--primary);
            cursor: pointer;
          }
        }
      }

      .col {
        align-items: center;
        column-gap: 16px;
      }
    }

    // .slider {

    // }

    // .slider:hover {
    //
    // }

    .checkboxes.row {
      justify-content: space-evenly !important;
    }

    .enable-wrapper {
      display: flex;
      justify-content: space-between !important;
      margin-bottom: 20px;

      .flex {
        justify-content: center;
      }

      .col {
        margin-right: 10px;
      }

      @media screen and (max-width: 850px) {
        .flex {
          justify-content: initial;
        }

        .col.quarter {
          width: 100% !important;
        }
      }
    }

    .customized-options-wrapper {
      div.row {
        margin-top: 30px;

        h3 {
          margin: 0;
        }

        .dropdown-container {
          border: 1px solid var(--dashboard-border);
          box-shadow: var(--dashboard-shadow);
          align-items: center;
          border-top-right-radius: inherit;
          display: flex;
          flex-wrap: wrap;
          font-size: 0.9375rem;
          line-height: 1;
          justify-content: center;
          border-radius: 10px;
          outline: none;
          cursor: pointer;
          position: relative;
          transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
          width: 100%;
          user-select: none;
          margin-bottom: 20px;
          -webkit-user-select: none;

          .title-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 15px;
            justify-content: space-between;

            svg {
              font-size: 30px;
            }
          }

          .service-container {
            margin: 10px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }

          .service-wrapper {
            margin: 10px;
            width: 100%;
            display: flex;
            align-items: center;

            .service-count {
              min-width: 30px;
              min-height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: #3a885d;
              margin-right: 10px;
              color: white;
            }

            .input-wrapper {
              margin: 0;
            }

            .cash-check {
              margin: 0 10px;
            }
          }

          .add-service-wrapper {
            margin: 10px;
            width: 100%;
            display: flex;
            align-items: center;

            .form-col {
              margin-right: 0.5em;
            }

            .range-divide {
              margin: 0 10px;
            }

            button {
              margin-left: 10px;
            }

            .input-wrapper {
              margin: 0;
            }

            .cash-check {
              margin: 0 10px;
            }
          }
        }
      }
    }

    .formulate-input[data-classification="box"] .formulate-input-wrapper {
      justify-content: center;
      margin-bottom: 1.5em;
    }
  }
}
</style>
