<template>
  <div class="sections-container">
    <div class="row">
      <div class="col half flex center">
        <FormulateInput
          type="multiSelect"
          label="Add Previously Entered Sections"
          placeholder="Select"
          :options="previousItems"
          v-model="previousItemsAdded"
          @input="addPreviousBottle"
          autocomplete="false"
        />
      </div>
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <FormulateForm @submit="submit(index)" :key="index">
        <div class="row">
          <div class="col"  :class="[{ full: useReservSeats },{ half: !useReservSeats }]">
            <FormulateInput
              label="Section Name"
              placeholder="Section Name"
              type="text"
              v-model="section.name"
              name="name"
              :disabled="section.sold > 0"
              :validation="['required']"
              :validation-messages="{ in: 'This name is already taken.' }"
            />
          </div>
          <div class="col half" v-if="useReservSeats">
            <FormulateInput
                type="text"
                name="category_key"
                v-model:style="section.category_key"
                label="Category Key"
                placeholder="Obtain from categories in Reserved seats plan"
                validation="required"
                autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              label="Section Image"
              type="image"
              v-model="section.image"
              name="image"
            />
          </div>
          <div class="col full">
            <FormulateInput
              type="text"
              label="Description"
              placeholder="Add something to write about your section."
              v-model="section.description"
              validation="max:200"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="checkbox"
              v-model="section.show_check_out_add_on"
              name="show_check_out_add_on"
              label="Make available as an add-on/upsell at checkout."
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="checkbox"
              v-model="section.do_not_show_in_guestlist"
              name="do_not_show_in_guestlist"
              label="Do not display on guest list."
            />
          </div>
          <div class="col tenth">
            <FormulateInput
              type="money"
              name="price"
              label="Price"
              :disabled="!paid"
              v-model="section.price"
              @input="calcFees(index)"
              :placeholder="paid ? 'Price' : '0.00'"
              :validation="paid ? 'required' : ''"
              autocomplete="false"
            />
          </div>
          <div class="col tenth">
            <FormulateInput
              type="money"
              name="ccfee"
              v-model="section.ccfee"
              disabled="true"
              label="CC Fee"
              placeholder="0.00"
              autocomplete="false"
            />
          </div>
          <div class="col tenth">
            <FormulateInput
              type="money"
              name="tfee"
              v-model="section.tfee"
              disabled="true"
              label="T-Fee"
              placeholder="0.00"
              autocomplete="false"
            />
          </div>
          <div class="col tenth">
            <FormulateInput
              type="money"
              name="addfee"
              @input="calcFees(index)"
              :disabled="!paid"
              v-model="section.addfee"
              label="Add Fees"
              placeholder="0.00"
              autocomplete="false"
            />
          </div>
          <div class="col tenth">
            <FormulateInput
              type="money"
              name="total"
              v-model="section.total"
              label="Total"
              disabled="true"
              placeholder="0.00"
              autocomplete="false"
            />
          </div>
          <div class="col quarter">
            <FormulateInput
              type="number"
              name="quantity"
              v-model="section.quantity"
              label="Available Quantity"
              placeholder="Enter amount"
              autocomplete="false"
              validation="required"
              :min="section.sold || 0"
            />
          </div>
          <div class="col tenth update-btn">
            <FormulateInput
              type="submit"
              :name="section.btn || 'Save'"
              :disabled="isSubmitted === index"
              autocomplete="false"
            />
          </div>
          <div class="col tenth delete">
            <FormulateInput
              type="button"
              autocomplete="false"
              name="Delete"
              :disabled="isDeleted === index || section.sold > 0"
              @click.prevent="handleRemove(index)"
            />
          </div>
          <div class="col full">
            <div class="divider"></div>
          </div>
        </div>
      </FormulateForm>
    </div>
    <FormulateInput
      class="add-button"
      type="button"
      name="Add Another Section"
      autocomplete="false"
      @click.prevent="addMore"
    />
  </div>
</template>

<script>
export default {
  name: "sections",
  props: {
    settings_id: String,
    tfees: Array,
    paid: Boolean,
    useReservSeats: Boolean
  },
  data() {
    return {
      isSubmitted: "",
      isDeleted: "",
      allSections: [],
      sections: [],
      allPreviousItems: [],
      previousItems: [],
      previousItemsAdded: [],
      ccPercent: 0.026
    };
  },
  mounted() {
    this.$axios
      .post("/guestlist/sections/all", {
        setting_id: this.settings_id
      })
      .then(response => {
        this.allSections.push(...response.data.items);
        response.data.items.forEach(ele => {
          ele.btn = "Update";
          if (ele.image_url) {
            ele.image = {
              image: process.env.VUE_APP_IMAGE_URL + ele.image_url,
              imageName: ele.image_name
            };
          }
          this.sections.push(ele);
          this.calcFees(this.sections.length - 1);
        });
        if (!this.sections.length) {
          this.sections.push({ btn: "Save" });
        }
      });

    this.getAllPrevious();
  },
  methods: {
    calcFees(index) {
      if (this.paid) {
        this.calcTFee(index);
        this.calcCCFee(index);
        this.calcTotal(index);
      }
    },
    calcTotal(index) {
      let section = this.sections[index];
      let total = 0;
      total += Number(section.price);
      total += Number(section.tfee);
      total += Number(section.ccfee);
      total += Number(section.addfee || 0);

      this.$set(this.sections[index], "total", total.toFixed(2));
    },
    calcCCFee(index) {
      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   return Number(this.cost) * this.AMEXfee;
      // }
      //  (
      //   (Number(this.cost) +
      //     Number(this.formValues.dfee) +
      //     Number(this.taxes)) *
      //   this.ccPercent
      // );

      this.$set(
        this.sections[index],
        "ccfee",
        (
          (Number(this.sections[index].price || 0) +
            Number(this.sections[index].tfee || 0) +
            Number(this.sections[index].addfee || 0)) *
          Number(this.ccPercent)
        ).toFixed(2)
      );
    },
    calcTFee(index) {
      let price = this.sections[index].price || 0;
      let addfee = this.sections[index].addfee || 0;

      const cost = Number(price) + Number(addfee);
      let tfee = 0;
      for (let i = 0; i < this.tfees.length; i++) {
        let ele = this.tfees[i];
        if (cost >= ele.min && cost <= ele.max) {
          this.$set(this.sections[index], "tfee", ele.value.toFixed(2));
          tfee = ele.value;
          i = this.tfees.length;
        } else if (cost === 0) {
          this.$set(this.sections[index], "tfee", 0);
        }
      }

      // let VMCFee;
      // let AMEXFee;

      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   VMCFee = cost * this.VMCfee;
      //   AMEXFee = cost * this.AMEXfee;
      // } else {
      //   VMCFee = (cost + tfee + Number(this.taxes)) * this.VMCfee;
      //   AMEXFee = (cost + tfee + Number(this.taxes)) * this.AMEXfee;
      // }

      // this.formValues.VMCfee = VMCFee.toFixed(2);
      // this.formValues.AMEXfee = AMEXFee.toFixed(2);
    },
    checkUniqueName(index) {
      let name = this.sections[index].name || "";
      let names = [];
      this.sections.forEach((sect, i) => {
        if (i != index && sect.name) {
          names.push(sect.name);
        }
      });
      names.push(...this.previousItems.map(s => s.label));
      return names.includes(name) ? "in" : true;
    },
    addMore() {
      let lastItem = this.sections[this.sections.length - 1];
      if (!lastItem.name || (lastItem.price == undefined || lastItem.price == null ) || !lastItem.quantity) {
        this.$toast("Please double check the required fields for last section added item.", {
          type: "error"
        });

        return;
      }
      this.sections.push({ btn: "Save" });
    },
    submit(index) {
      this.isSubmitted = index;
      let item = this.sections[index];
      let data = new FormData();

      data.append("setting_id", this.settings_id);
      data.append("url", this.$route.params.url);
      data.append("addfee", item.addfee || 0);
      data.append("name", item.name);
      data.append("description", item.description || "");
      data.append("price", item.price || 0);
      data.append("quantity", item.quantity);
      data.append("show_check_out_add_on", item.show_check_out_add_on);
      data.append("do_not_show_in_guestlist", item.do_not_show_in_guestlist);
      data.append('category_key', item.category_key);
      data.append("_id", item._id);
      if (item.image && item.image.imageFile) {
        data.append("file", item.image.imageFile);
      }
      if (item._id && !item.copy) {
        //update
        data.append("_id", item._id);
        this.$axios.post("/guestlist/sections/update", data).then(resp => {
          this.allSections[index] = resp.data.item;
          resp.data.item.btn = "Update";
          if (resp.data.item.image_url) {
            this.sections[index].image = {
              image: process.env.VUE_APP_IMAGE_URL + resp.data.item.image_url,
              imageName: resp.data.item.image_name
            };
          }
          this.$toast(resp.data.message, { type: "success" });
        });
      } else {
        //create
        this.$axios.post("/guestlist/sections/create", data).then(resp => {
          this.allSections[index] = structuredClone(resp.data.item);
          resp.data.item.btn = "Update";
          this.$set(this.sections, index, resp.data.item);
          if (resp.data.item.image_url) {
            this.sections[index].image = {
              image: process.env.VUE_APP_IMAGE_URL + resp.data.item.image_url,
              imageName: resp.data.item.image_name
            };
          }
          this.$toast(resp.data.message, { type: "success" });
        });
      }
      setTimeout(() => {
        this.$emit('changed');
        this.isSubmitted = "";
      }, 1000);
    },
    handleRemove(index) {
      this.isDeleted = index;
      this.sections.splice(index, 1);
      if (!this.sections.length) {
        this.sections.push({});
      }
      if (this.allSections[index]) {
        this.$axios
          .post("/guestlist/sections/delete", {
            id: this.allSections[index]["_id"],
            url: this.$route.params.url
          })
          .then(resp => {
            this.allSections.splice(index, 1);
            this.$toast(resp.data.message, { type: "error" });
          });
      }
      setTimeout(() => {
        this.isDeleted = "";
      }, 1000);
    },
    getAllPrevious() {
      this.$axios
        .post("/guestlist/sections/get-all-previous")
        .then(({ data }) => {
          let newItems = [];
          data.items.forEach(item => {
            newItems.push({
              label: `${item.name}: $${item.price.toFixed(2)}`,
              value: item._id
            });
          });
          this.previousItems = newItems;
          this.allPreviousItems = data.items;
        });
    },
    addPreviousBottle(items) {
      items.forEach(item => {
        let previousOG = this.allPreviousItems.find(bottle => {
          return bottle._id === item;
        });

        let previousCopy = JSON.parse(JSON.stringify(previousOG));

        this.sections.forEach((val, index) => {
          if (!val.name || !val.price || !val.quantity) {
            this.sections.splice(index, 1);
          }
        });

        if (previousCopy.image_url) {
          previousCopy.image = {
            image: process.env.VUE_APP_IMAGE_URL + previousCopy.image_url,
            imageName: previousCopy.image_name
          };
        }
        previousCopy.copy = true;

        this.sections.push(previousCopy);
      });
      this.previousItemsAdded = [];

      this.$toast("Previous item was added", { type: "success" });
    }
  }
};
</script>

<style lang="less" scoped>
.col.tenth.update-btn {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="submit"] button {
    height: 48px;
    width: 100%;
  }
}

.col.tenth.delete {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="button"] button {
    background: var(--error-red);
    height: 48px;
    width: 100%;
  }
}

.add-button {
  display: flex;
  justify-content: center;
}
</style>