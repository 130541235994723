<template>
  <div class="groups-container">
    <div class="row">
      <div class="col half flex center">
        <FormulateInput
          type="multiSelect"
          label="Add Previously Entered Groups"
          placeholder="Select"
          :options="previousItems"
          v-model="previousItemsAdded"
          @input="addPreviousGroup"
          autocomplete="false"
        />
      </div>
    </div>
    <div v-for="(group, index) in groups" :key="index">
      <group-form
        :group="group"
        :tfees="tfees"
        :dropSections="dropSections"
        :drop-foods="dropFoods"
        :drop-bottles="dropBottles"
        :sections="sections"
        :foods="foods"
        :bottles="bottles"
        :settings_id="settings_id"
        @remove="handleRemove(index)"
        @save="submit(index)"
      ></group-form>
    </div>
    <FormulateInput
      class="add-button"
      type="button"
      name="Add Another Package"
      autocomplete="false"
      @click.prevent="addMore"
    />
  </div>
</template>

<script>
import GroupForm from "./GroupForm.vue";
export default {
  name: "combo",
  props: {
    settings_id: String,
    tfees: Array,
    hasChanges: Boolean,
  },
  components: {
    GroupForm
  },
  watch: {
    hasChanges() {
      this.getAllPrevious();
    }
  },
  data() {
    return {
      isSubmitted: "",
      isDeleted: "",
      allSections: [],
      groups: [],
      allPreviousItems: [],
      previousItems: [],
      previousItemsAdded: [],
      ccPercent: 0.026,
      sections: [],
      bottles: [],
      foods: [],

      dropSections: [],
      dropBottles: [],
      dropFoods: []
    };
  },
  mounted() {
    this.getAllPrevious();
    this.$axios
      .post("/guestlist/groups/all", {
        setting_id: this.settings_id
      })
      .then(response => {
        this.allSections.push(...response.data.items);
        response.data.items.forEach(ele => {
          ele.btn = "Update";
          if (ele.image_url) {
            ele.image = {
              image: process.env.VUE_APP_IMAGE_URL + ele.image_url,
              imageName: ele.image_name
            };
          }
          ele.sections.forEach(s => {
            this.$set(s, "_id", s.id.$oid);
            this.$set(s, "oldQty", s.quantity);
          });
          ele.foods.forEach(s => {
            this.$set(s, "_id", s.id.$oid);
            this.$set(s, "oldQty", s.quantity);
          });
          ele.bottles.forEach(s => {
            this.$set(s, "_id", s.id.$oid);
            this.$set(s, "oldQty", s.quantity);
          });
          this.groups.push(ele);
          this.itemPrices(this.groups.length - 1);
          this.calcFees(this.groups.length - 1);
        });
        if (!this.groups.length) {
          this.groups.push({
            btn: "Save",
            sections: [],
            foods: [],
            bottles: []
          });
        }
      });
  },

  methods: {
    getSelectedSection(index, group) {
      if (group.sections[index]) {
        return group.sections[index].id.$oid;
      }
      return null;
    },
    getSelectedBottle(index, group) {
      if (group.bottles[index]) {
        return group.bottles[index].id.$oid;
      }
      return null;
    },
    getSelectedFood(index, group) {
      if (group.foods[index]) {
        return group.foods[index].id.$oid;
      }
      return null;
    },
    itemPrices(index) {
      if (typeof index !== "number") {
        return;
      }
      let sectionsTotal = 0.0;
      let bottlesTotal = 0.0;
      let foodsTotal = 0.0;
      if (
        this.groups[index].sections &&
        this.groups[index].sections.length > 0
      ) {
        this.groups[index].sections.forEach(groupSection => {
          let id = groupSection._id;
          let item = this.sections.find(s => s._id === id);
          sectionsTotal += groupSection.quantity * item.price;
        });
      }

      if (this.groups[index].bottles && this.groups[index].bottles.length > 0) {
        this.groups[index].bottles.forEach(groupBottle => {
          let id = groupBottle._id;
          let item = this.bottles.find(s => s._id === id);
          bottlesTotal += groupBottle.quantity * item.price;
        });
      }
      if (this.groups[index].foods && this.groups[index].foods.length > 0) {
        this.groups[index].foods.forEach(groupFood => {
          let id = groupFood._id;
          let item = this.foods.find(s => s._id === id);
          foodsTotal += groupFood.quantity * item.price;
        });
      }

      let total = (sectionsTotal + bottlesTotal + foodsTotal).toFixed(2);
      this.$set(this.groups[index], "item_prices", total);
    },
    calcFees(index) {
      this.calcTFee(index);
      this.calcCCFee(index);
      this.calcTotal(index);
    },
    calcTotal(index) {
      let section = this.groups[index];
      let total = 0;
      total += Number(section.price);
      total += Number(section.tfee);
      total += Number(section.ccfee);
      total += Number(section.addfee || 0);

      this.$set(this.groups[index], "total", total);
    },
    calcCCFee(index) {
      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   return Number(this.cost) * this.AMEXfee;
      // }
      //  (
      //   (Number(this.cost) +
      //     Number(this.formValues.dfee) +
      //     Number(this.taxes)) *
      //   this.ccPercent
      // );

      this.$set(
        this.groups[index],
        "ccfee",
        (
          (Number(this.groups[index].price || 0) +
            Number(this.groups[index].tfee || 0) +
            Number(this.groups[index].addfee || 0)) *
          Number(this.ccPercent)
        ).toFixed(2)
      );
    },
    calcTFee(index) {
      let price = this.groups[index].price || 0;
      let addfee = this.groups[index].addfee || 0;

      const cost = Number(price) + Number(addfee);
      let tfee = 0;
      for (let i = 0; i < this.tfees.length; i++) {
        let ele = this.tfees[i];
        if (cost >= ele.min && cost <= ele.max) {
          this.$set(this.groups[index], "tfee", ele.value.toFixed(2));
          tfee = ele.value;
          i = this.tfees.length;
        } else if (cost === 0) {
          this.$set(this.groups[index], "tfee", 0);
        }
      }

      // let VMCFee;
      // let AMEXFee;

      // if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
      //   VMCFee = cost * this.VMCfee;
      //   AMEXFee = cost * this.AMEXfee;
      // } else {
      //   VMCFee = (cost + tfee + Number(this.taxes)) * this.VMCfee;
      //   AMEXFee = (cost + tfee + Number(this.taxes)) * this.AMEXfee;
      // }

      // this.formValues.VMCfee = VMCFee.toFixed(2);
      // this.formValues.AMEXfee = AMEXFee.toFixed(2);
    },
    checkUniqueName(index) {
      let name = this.groups[index].name || "";
      let names = [];
      this.groups.forEach((sect, i) => {
        if (i != index && sect.name) {
          names.push(sect.name);
        }
      });
      names.push(...this.previousItems.map(s => s.label));
      return names.includes(name) ? "in" : true;
    },
    addMore() {
      let lastItem = this.groups[this.groups.length - 1];
      if (!lastItem.name || (lastItem.price == undefined || lastItem.price == null ) || !lastItem.quantity) {
        this.$toast("Please double check the required fields for last group added item.", {
          type: "error"
        });

        return;
      }
      this.groups.push({ btn: "Save", sections: [], foods: [], bottles: [] });
    },
    submit(index) {
      let sectionsLength = this.groups[index].sections
        ? this.groups[index].sections.length
        : 0;
      let bottlesLength = this.groups[index].bottles
        ? this.groups[index].bottles.length
        : 0;
      let foodsLength = this.groups[index].foods
        ? this.groups[index].foods.length
        : 0;

      if (sectionsLength + bottlesLength + foodsLength < 2) {
        this.groups[index].formError = true;
        return;
      }
      this.isSubmitted = index;
      let item = this.groups[index];
      let data = new FormData();

      data.append("setting_id", this.settings_id);
      data.append("url", this.$route.params.url);
      data.append("addfee", item.addfee);
      data.append("name", item.name);
      data.append("description", item.description || "");
      data.append("price", item.price);
      data.append("item_prices", item.item_prices);

      (item.sections || []).map(i => (i.id = i._id || i.id));
      (item.bottles || []).map(i => (i.id = i._id || i.id));
      (item.foods || []).map(i => (i.id = i._id || i.id));
      data.append("sections", JSON.stringify(item.sections));
      data.append("bottles", JSON.stringify(item.bottles));
      data.append("foods", JSON.stringify(item.foods));

      data.append("quantity", item.quantity);
      data.append("show_check_out_add_on", item.show_check_out_add_on);
      data.append("do_not_show_in_guestlist", item.do_not_show_in_guestlist);
      data.append("_id", item._id);
      if (item.image && item.image.imageFile) {
        data.append("file", item.image.imageFile);
      }
      if (item._id && !item.copy) {
        //update
        data.append("_id", item._id);
        this.$axios.post("/guestlist/groups/update", data).then(resp => {
          this.allSections[index] = resp.data.item;
          resp.data.item.btn = "Update";
          if (resp.data.item.image_url) {
            this.groups[index].image = {
              image: process.env.VUE_APP_IMAGE_URL + resp.data.item.image_url,
              imageName: resp.data.item.image_name
            };
          }
          this.$toast(resp.data.message, { type: "success" });
        });
      } else {
        //create
        this.$axios.post("/guestlist/groups/create", data).then(resp => {
          this.allSections[index] = structuredClone(resp.data.item);
          resp.data.item.btn = "Update";
          this.$set(this.groups, index, resp.data.item);
          if (resp.data.item.image_url) {
            this.groups[index].image = {
              image: process.env.VUE_APP_IMAGE_URL + resp.data.item.image_url,
              imageName: resp.data.item.image_name
            };
          }
          this.$toast(resp.data.message, { type: "success" });
        });
      }
      setTimeout(() => {
        this.isSubmitted = "";
      }, 1000);
    },
    handleRemove(index) {
      this.groups.splice(index, 1);
      if (!this.groups.length) {
        this.groups.push({});
      }
      if (this.allSections[index]) {
        this.$axios
          .post("/guestlist/groups/delete", {
            id: this.allSections[index]["_id"],
            url: this.$route.params.url
          })
          .then(resp => {
            this.allSections.splice(index, 1);
            this.$toast(resp.data.message, { type: "error" });
          });
      }
    },
    getAllPrevious() {
      this.$axios
        .post("/guestlist/groups/get-all-items", {
          setting_id: this.settings_id
        })
        .then(({ data }) => {
          this.sections = data.sections;
          this.bottles = data.bottles;
          this.foods = data.foods;

          let newItems = [];
          data.groups.forEach(item => {
            newItems.push({
              label: `${item.name} - $${item.price.toFixed(2)}`,
              value: item._id
            });
          });
          this.previousItems = newItems;
          this.allPreviousItems = data.groups;
          this.generateDropdown();
        });
    },
    generateDropdown() {
      this.dropFoods = [];
      this.dropBottles = [];
      this.dropSections = [];
      this.sections.forEach(item => {
        this.dropSections.push({ label: item.name, value: item._id });
      });
      this.bottles.forEach(item => {
        this.dropBottles.push({ label: item.name, value: item._id });
      });
      this.foods.forEach(item => {
        this.dropFoods.push({ label: item.name, value: item._id });
      });
    },
    addPreviousGroup(items) {
      items.forEach(item => {
        let previousOG = this.allPreviousItems.find(group => {
          return group._id === item;
        });

        let previousCopy = JSON.parse(JSON.stringify(previousOG));

        this.groups.forEach((val, index) => {
          if (!val.name || !val.price || !val.quantity) {
            this.groups.splice(index, 1);
          }
        });

        if (previousCopy.image_url) {
          previousCopy.image = {
            image: process.env.VUE_APP_IMAGE_URL + previousCopy.image_url,
            imageName: previousCopy.image_name
          };
        }
        previousCopy.copy = true;

        previousCopy.sections.map(item => {
          item._id = item.id.$oid;
        });

        this.groups.push(previousCopy);
      });
      this.previousItemsAdded = [];
      this.$toast("Previous item was added", { type: "success" });
    }
  }
};
</script>

<style lang="less" scoped>
.red {
  color: red;
  text-align: center;
  margin-bottom: 32px;

  p {
    margin: 0;
    font-size: 16px;
  }
}

.col.tenth.update-btn {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="submit"] button {
    height: 48px;
    width: 100%;
  }
}

.col.tenth.delete {
  display: flex;
  align-items: flex-end;

  ::v-deep .formulate-input[data-classification="button"] button {
    background: var(--error-red);
    height: 48px;
    width: 100%;
  }
}

.add-button {
  display: flex;
  justify-content: center;
}
</style>